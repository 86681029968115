document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--video');
	
	function create_block_video() {

		return {

			block: null,

			init: function (block) {

				this.block = block;
				this.handlers();

				let player = this.block.querySelector('.video');
				let autoplay = player.dataset.autoplay
				console.log('autoplay', autoplay);
				if (autoplay == 1){
					this.play();
				}
								
			},

			handlers: function () {

				const openHandler = () => {
					this.open();
				};

				let wrapper = this.block.querySelector('.video-wrapper');
				let backdrop = this.block.querySelector('.backdrop');
				
				wrapper.addEventListener('click', function() {
					openHandler();
				});

				backdrop.addEventListener('click', function() {
					openHandler();
				})
				
			},

			open: function () {

				const loadHandler = () => {
					this.load();
				};

				const unloadHandler = () => {
					this.unload();
				};

				const playHandler = () => {
					this.play();
				}

				const expandableDiv = this.block.querySelector('.video');
				const isExpanded = this.block.classList.contains('expanded');

				if (expandableDiv.dataset.inPlace == 'true') {
					playHandler();
					return;
				}

				const windowLeft = window.innerWidth * 0.2;
				const windowTop = window.innerHeight * .21875;

				if (!isExpanded) {
					let rect = expandableDiv.getBoundingClientRect();

					if ( window.matchMedia('(min-width: 992px)').matches ) {
						expandableDiv.style.transform = `translate(${windowLeft - rect.left}px, ${windowTop - rect.top}px)`;
					} else {
						expandableDiv.style.transform = `translateY(${windowTop - rect.top}px)`;
					}
					
					loadHandler();

					document.body.style.overflow = 'hidden';

				} else {
					document.body.style.overflow = 'auto';
					expandableDiv.style.transform = 'translate(0, 0)';
					unloadHandler();
				}

				this.block.classList.toggle('expanded');


			},
			
			load: function () {

				const unloadHandler = () => {
					this.unload();
				};

				let player = this.block.querySelector('.video');
				let src = player.dataset.src;
				let type = player.dataset.type

				if ( type == 'vimeo' ) {
					let id = src.split('/').pop();
	
					player.innerHTML = `<button class="close">Close &times;</button><iframe src="https://player.vimeo.com/video/${id}?autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
	
				} else if ( type == 'self' ) {
	
					player.innerHTML = `<button class="close">Close &times;</button><video aria-label="Media Player" preload="auto" controls autoplay><source type="video/mp4" src="${src}"/></video>`
				}

				let close = player.querySelector('.close');
				close.addEventListener('click', function() {
					unloadHandler();
				});
				
			},
		
			unload: function() {
				let player = this.block.querySelector('.video');
				player.innerHTML = '';
				
			},

			play: function() {

				let player = this.block.querySelector('.video');
				let src = player.dataset.src;
				let type = player.dataset.type
				let autoplay = player.dataset.autoplay
				let muted = '';

				if (autoplay == 1){
					muted = '&muted=1';
				}

				if ( type == 'vimeo' ) {
					let id = src.split('/').pop();
	
					player.innerHTML = `<iframe src="https://player.vimeo.com/video/${id}?autoplay=1${muted}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
	
				} else if ( type == 'self' ) {
	
					player.innerHTML = `<video aria-label="Media Player" preload="auto" controls autoplay><source type="video/mp4" src="${src}"/></video>`
				}

			},
			
			log: function() {
				console.log('test');
			}
		}
	}

	blocks.forEach( block => {
		const block_video = create_block_video();
		block_video.init(block);
	});
});